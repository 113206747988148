.custom-node__root {
  border-radius: 9px;
  padding: 4px;
  color: #141218;
  background: white;
}

.custom-node__play-arrow {
  transform: translate(-50%, 0) rotate(90deg);
  left: auto;
  right: auto;
  position: absolute;
  pointer-events: none;
}

.custom-node__play-arrow.out {
  top: -4px;
  font-size: 16px;
  color: #ffffff;
}

.custom-node__play-arrow.in {
  top: -1px;
  font-size: 10px;
  color: #79747e;
}

.custom-node__circle {
  transform: translate(-50%, 0);
  left: auto;
  right: auto;
  position: absolute;
  pointer-events: none;
}

.custom-node__circle.out {
  bottom: 0;
  font-size: 8px;
  color: #ffffff;
}

.custom-node__circle.in {
  bottom: 1px;
  font-size: 6px;
  color: #79747e;
}
