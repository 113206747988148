.edgebutton {
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 100%;
  font-size: 8px;
  text-align: center;
  font-weight: bold;
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
  user-select: none;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.stroke-width-2 {
  stroke-width: 2 !important
}
