.dndflow {
    flex-direction: row;
    display: flex;
    flex-grow: 1;
    height: 100%;
}

.dndflow aside {
    padding: 15px 10px;
    font-size: 16px;
}

.dndflow aside .description {
    margin-bottom: 10px;
}

.dndflow .dndnode {
    height: 40px;
    padding: 4px;
    border: 2px solid #78787b;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
}

.dndflow .dndnode.input {
    border-color: #0041d0;
}

.dndflow .dndnode.output {
    border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
}

.dndflow .selectall {
    margin-top: 10px;
}

@media screen and (min-width: 768px) {
    .dndflow {
        flex-direction: column;
    }

    .dndflow aside {
        width: 15%;
        max-width: 250px;
    }
}

.react-flow__node {
    padding: 0%;
}

.react-flow__handle {
    border: 0;
    background: none;
    border-radius: 0%;
    width: 0px;
    height: 0px;
}

.react-flow__node-default {
    border: 0 !important;
} 